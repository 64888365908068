/* Rollover styles */

/* Base styling for action buttons */
.action-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 31px !important;
  height: 31px !important;
  /* color: white; */
}
.action-button:hover {
  transition: none !important;
}

.action-button.add {
  background-color: #ebf2ff;
  border-color: transparent;
}
.action-button.add:hover {
  background-color: #ebf2ff !important;
  border: 2px solid #00308733 !important;
}

.action-button.swap {
  background-color: #fdf1e9;
  border-color: transparent;
}
.action-button.swap:hover {
  background-color: #fdf1e9 !important;
  border: 2px solid #e8772233 !important;
}

.action-button.delete {
  background-color: #ffe5e5;
  border-color: transparent;
}
.action-button.delete:hover {
  background-color: #ffe5e5 !important;
  border: 2px solid #ff000033 !important;
}

/* Cancel button */
.cancel-button {
  font-size: 16px;
  border: none;
  position: absolute;
  right: -50px;
  top: -50px;
  padding: 15px;
  background: #fff;
  border-radius: 22px;
  border: solid 1px #000;
}
.cancel-button:hover {
  cursor: pointer;
}
.ant-modal .ant-modal-content {
  border-radius: 24px;
}
.ant-btn.ant-btn-icon-only {
  width: 44px;
  height: 44px;
  padding-inline: 0;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* MIGHT NEED TO REMOVE PADDING */
.modal-container .ant-modal-content {
  padding: 35px;
}
.replace-wordorder-badge {
  padding: 2px 12px;
  background: #f2f2f2;
  border-radius: 40px;
  font-size: 14px !important;
  margin: 0 8px;
  font-weight: 400 !important;
}
.removed-wo-listing {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}
.removed-wo-listing.ant-list-split .ant-list-item {
  border-block-end: none;
}
.add-btn-active {
  background: #003087;
  color: #fff;
}
.add-btn-active:hover {
  background-color: #003087 !important;
  color: #fff !important;
  cursor: pointer;
}
.add-btn-inactive {
  background: #c6c6c6;
  color: #9a9a9a;
}
.custom-rollover-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;
}
.custom-rollover-modal p {
  align-self: flex-start;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.custom-rollover-modal div {
  /* display: flex;
  width: 100%; */
}
.rollover-btn-save {
  height: 45px;
  width: 131px;
  border-radius: 48px;
}
.action-button-tooltip {
  background-color: #fff;
  color: #000;
  padding: 15px;
}
.ant-tooltip {
  --antd-arrow-background-color: #fff; /* Changes the arrow color */
}
.rollover-add-workorder-input {
  border-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 47px;
  width: 100%;
}
.rollover-add-workorder-btn {
  height: 47px;
  width: 88px;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.rollover-save-workorder-btn {
  height: 45px;
  border-radius: 48px;
  width: 131px;
}
.replace-wo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  /* width: 90%; */
  /* margin-left: -8px; */
}
.replace-wo-input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sub-heading {
  font-size: 16px;
  margin: 0;
  padding: 0;
  align-self: flex-start;
}
.replace-wo-label {
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 10px;
}
.replace-wo-input-left {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.replace-wo-input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 185px;
}
.replace-wo-input-text {
  width: 100%;
  height: 47px;
  border-radius: 8px;
  border: 1px solid #c6c6c6;
}
.replace-wo-input-text::placeholder {
  color: #8c8c8c;
}
.replace-wo-input-select {
  width: 100%;
  height: 47px;
  border: 1px solid #979797;
  border-radius: 8px;
}
.replace-wo-input-select div {
  background: #fafafa !important;
  color: #000 !important;
}
.replace-wo-input-select div span {
  color: #000 !important;
}
.swaped-icon-container {
  display: flex;
  margin-top: 25px;
  align-items: center;
}
.swaped-icon {
  background-color: #e1ecff;
  color: #003087;
  padding: 5px;
  border-radius: 12px;
}
.replace-btn {
  height: 47px;
  width: 88px;
  border-radius: 8px;
  background-color: #002366;
  color: #fff;
  align-self: flex-end;
}
.replace-btn:disabled {
  background-color: unset !important;
  color: #9A9A9A;
}

.remove-wo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  /* padding: 0 25px 25px 25px; */
}
.remove-wo-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.remove-wo-list-item-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #e1e1e1;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgb(0, 0, 0, 0.05);
  flex-grow: 1;
}
.remove-wo-list-item-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.wo-title {
  color: #8c8c8c;
}
.wo-no {
  font-weight: 500;
  color: #000;
}
.down-arrow {
  color: #003087;
  padding: 6px;
  border-radius: 20px;
  background-color: #e6efff;
}
.up-arrow {
  color: #003087;
  padding: 6px;
  border-radius: 20px;
  background: #e6efff;
}
.remove-icon {
  color: #ff0000;
  padding: 10px;
  border-radius: 20px;
  background-color: #ffe5e5;
  cursor: pointer;
}
.modal-workorder-details-card {
  border: 1px solid #e1e1e1;
  box-shadow: 2px 2px 12px 0px #00000014;
  padding: 20px;
  border-radius: 12px;
  width: 95%;
}
.add-wo-loader {
  color: #fff;
}
.replace-wordorder-static-text {
  color: #8c8c8c;
}

.ant-checkbox-inner {
  border: 1px solid #003087 !important; /* Checkbox border */
  background-color: #fff; /* Background color */
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #003087 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #003087 !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #003087 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #003087 !important;
  background-color: #fff !important;
}

/* Select All Checkbox Styling */
.ant-table-selection .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #003087 !important;
}

.ant-table-selection .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #fff !important;
}

.action-button-group {
  margin: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  box-shadow: 0px 2px 7px 0px #00000017;
  padding: 10px 15px;
  border-radius: 8px;
}
.wo-details-group {
  display: flex;
  width: 100%;
}
.custom-modal-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.custom-modal-title {
  font-size: 22px;
  font-weight: 500;
}
.custom-modal-children {
  display: flex;
  justify-content: center;
}

.action-button-disabled {
  background-color: #E8E8E8;
  width: 31px !important;
  height: 31px !important;
}

.action-button-disabled:disabled {
  background-color: #E8E8E8 ;
  border: 1px solid #E8E8E8;
}

.action-disabled {
  fill: #999999;
}

.action-remove {
  fill: #ff0000;
}

.action-replace {
  fill: #e87722;
}

.action-add {
  fill: #003087;
}