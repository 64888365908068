/*error message*/
.login-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.login-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.login-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.login-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border-color: rgb(198, 198, 198);
  box-shadow: none;
}

.login-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.login-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: 1px solid rgb(198, 198, 198);
  box-shadow: none;
}
.password
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.password
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border: none !important;
}
.login-form .ant-form-item-explain.ant-form-item-explain-error {
  color: rgb(255, 72, 39);
  text-align: left;
}
.login-container {
  background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-size: 33% 100%;
  background-color: rgb(247, 248, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-family: Helvetica;
}
.box-modal {
  /* height: 100vh; */
}
.logo-container {
  /* margin-top: 20%; */
  margin-bottom: 10%;
}
.copyright-container {
  color: rgb(154, 154, 154);

  font-size: 16px;
  position: fixed;
  bottom: 2%;
}
@media screen and (max-width: 358px) {
  .copyright-container {
    font-size: 14px;
  }
}
.copyright-text {
  color: rgb(154, 154, 154);
}

.login-box {
  box-shadow: 10px 10px 30px rgb(0 0 0/ 0.04);
}
.login-box .ant-form-vertical .ant-form-item {
  margin-top: 25px;
  margin-bottom: 0;
}

.login-form .ant-input,
.login-form .ant-input-affix-wrapper {
  border: 1px solid rgb(198, 198, 198);
  border-radius: 4px;
}
.login-form .ant-input:hover,
.login-form .ant-input-affix-wrapper:hover {
  border: 1px solid rgb(0, 48, 135);
}
.login-form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid rgb(0, 48, 135);
}
.login-form .ant-input:focus,
.login-form .ant-input-affix-wrapper:focus,
.login-form .ant-input-affix-wrapper-focused,
.login-form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid rgb(0, 48, 135);
  box-shadow: none;
}
.password .ant-input:hover,
.password .ant-input:focus,
.password .ant-input-affix-wrapper:hover {
  border: none;
  box-shadow: none;
}
.ant-row.ant-form-item:focus-within label {
  color: black;
}
.login-form .ant-form-item-label > label {
  color: rgb(154, 154, 154);
  font-size: 13px;
}

/* .login-form .ant-form-item-label > label:focus-visible {
  color: black;
} */

.login-form-forgot {
  float: right;
  color: rgb(0, 48, 135);
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-button-container {
  float: right;
}
.login-box .ant-btn-primary {
  height: 48px;
  min-width: 87px;
  background: rgb(0, 48, 135);
  border: none;
}
.login-box .ant-btn-primary:hover {
  color: white;
  background: #003ba7;
}
.login-box .ant-btn-link {
  color: rgb(0, 48, 135);
  padding-right: 36px;
  border: none;
}
@media screen and (max-width: 358px) {
  .login-box .ant-btn-link {
    padding-right: 5px;
  }
}
.login-box .ant-btn-link:hover {
  color: rgb(98, 181, 229);
  /* color: #003ba7; */
}
.login-title {
  font-size: 32px;
  font-weight: bold;
  display: flex;
}
@media screen and (max-width: 358px) {
  .login-title {
    font-size: 28px;
  }
}
.reset-password-info {
  font-size: 16px;
  text-align: left;
  line-height: 20px;
}
.login-description {
  font-size: 16px;
  display: flex;
  text-align: left;
}
@media screen and (max-width: 358px) {
  .login-description {
    font-size: 14px;
  }
}
.login-form-items {
  padding-top: 30px;
}

.logo-back {
  display: flex;
}
.logo-check {
  padding: 0px 3px;
}
.login-validation-container {
  background-color: #fdf1e8;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 20px;
  border-radius: 4px;
}
.login-reset-container {
  background-color: rgb(242, 248, 228);
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 10px 10px 20px;
}
.login-reset-text {
  font-size: 16px;
  display: flex;
  padding-left: 12px;
  text-align: left;
}

.login-validation-text {
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  text-align: left;
  width: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
