.workorder-text {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
}
.workorder-facility {
  background-color: rgb(247, 248, 252);
  padding: 20px 56px;
  margin-bottom: 30px;
  margin-top: 10px;
  border-radius: 4px;
}
.workorder-facility-details {
  padding: 13px 12px;
  background-color: white;
  display: flex;
  border-radius: 4px;
}
.workorder-icon {
  background-color: rgb(247, 248, 252);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 16px;
  margin-top: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.workorder-primary {
  color: rgb(154, 154, 154);
}
.wre-opt-in-out-container {
  margin-top: 10px;
  padding: 20px;
}
.workorder-details-card {
  border: 1px solid #c6c6c6;
  padding: 20px;
  border-radius: 12px;
}

.workorder-details-container {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.workorder-details {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.workorder-details-container > *:not(:first-child) {
  border-left: 1px solid #e3e3e3;
  padding: 0 20px;
}

.workorder-search-input {
  padding: 20px 0;
}
.workorder-search-input > span {
  width: 100% !important;
}

.workorder-search-input
  .ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  height: 40px !important;
}

.select-subscription-type {
  width: 220px !important;
}

.workorder-search-input .ant-btn-primary[disabled] {
  color: black;
  border: 1px solid rgb(198, 198, 198) !important;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

@media (max-width: 991px) {
  .workorder-details-container > *:not(:first-child) {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px 10px;
  }

  .workorder-details-container > :nth-child(odd) {
    border-left: none;
    padding: 0
  }
  .login-validation-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .proctor-form {
    padding-bottom: 40px !important;
  }
}

@media (max-width: 767px) {
  .workorder-details-container > *:not(:first-child) {
    gap: 20px;
    padding: 0;
    border: none;
  }
}
.popover-workorder-text {
  color: #003087;
  border-radius: 6px;
  padding: 4px;
  border: 1px solid #e6eaf2;
  background-color: #f7f8fc;
  text-wrap: nowrap;
}
.popover-workorder-text > u {
  text-decoration: none;
}

.user-type-dropdown-status {
  width: 150px;
  height: 48px;
}
.remove-workorder-button > span {
  display: flex;
  gap: 2px;
}
.proctor-resend-invite-container {
  margin-top: 20px;
}
.wre-opt-in-out-container {
  padding: 10px 0;
}
.facility-names-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.remaining-facility-count {
  width: 43px;
  display: flex;
  justify-content: flex-start;
}

/* Registration Timeline */
.timeline-last-item .ant-timeline-item-tail {
  display: none;
}
.timeline-container {
  max-height: 300px;
  overflow: auto;
  padding: 5px;
}

/* Workorder status badge */
.status-badge {
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: #f0f0f0;
  width: 100%;
  border-radius: 8px;
  font-weight: 800;
}
.status-badge::before {
  content: "\00a0";
  width: 8px;
  height: 8px;
  background-color: #9a9a9a;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

/* Changes requested */
.timeline {
  padding-top: 80px;
}
.timeline-item {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 2px 8px;
  margin-top: 4px;
  display: inline;
}
.timeline-checked {
  font-size: 20px;
  color: #0cbc5d;
}
.timeline-info {
  font-size: 20px;
  color: #ffc107;
}
.timeline-collapse {
  margin-top: 35px;
}
.proctor-form-row {
  overflow: auto;
  min-height: 80vh;
  /* position: absolute; */
}
.proctor-form-col {
  border-right: 1px solid #c6c6c6;
  padding: 20px 0;
}
.proctor-form-col-right {
  border-right: none;
  padding: 20px 0;
}
.timeline-panel-header {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}
.workorder-card {
  width: 100%;
}
.proctor-form {
  width: 100%; /* Full width */
  margin: auto; /* Center the content horizontally */
  max-width: 1200px; /* Optional: Add a max-width to prevent it from stretching too wide */
  padding: 0 16px; /* Optional: Add padding to prevent content from touching the edges */
}

.client-workorder-search-input {
  padding: 20px 0;
}

.client-workorder-search-input input {
  height: 47px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.client-workorder-search-input > span {
  width: 100% !important;
}

.client-workorder-search-input
  .ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  height: 47px !important;
}
.client-workorder-search-input .ant-btn-primary {
  color: #9a9a9a;
  border: 1px solid rgb(198, 198, 198) !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.client-workorder-search-input .ant-btn-primary:hover {
  background-color: #003087 !important;
}
.timeline-collapse {
  background-color: #fff;
  display: flex;
  align-items: start;
}
.timeline-collapse .ant-collapse-header {
  padding: 0 !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expand-collapse-icon {
  background-color: #e6efff;
  border-radius: 50%;
  height: 24px;
  height: 24px;
}

/* .top-border-none {
  padding: 13px 5px !important;
} */

.top-border-none .ant-table-thead > tr > th:nth-last-child(2) {
  padding-left: 15px !important;
}

.top-border-none .ant-table-thead > tr > th:nth-last-child(2)::after {
  content: '' !important;
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  transform: translateY(-55%);
  height: 65% !important;
  width: 1px !important;
  background-color: #D7D9DD !important;
  margin-right: 2px !important;
}

.h-search-select {
  width: 100%;
}

.h-search-select .ant-select-selection-search-input {
  font-size: 14px !important;
  line-height: normal !important;
}

.h-search-select .ant-select-selection-item {
  font-size: 14px !important;
}

.col-search-field-space {
  width: 100%;
}

.col-search-text-input {
  font-size: 14px;
}

.clear-icon {
  color: #4C4C4C;
}

/* ------------ SEARCH FILTERS ------------ */
.search-input-filter .ant-input-clear-icon {
  /* to center the clear icon */
  line-height: 0;
}
.search-select-input-filter.hide-arrow-on-hover:hover .ant-select-arrow {
  visibility: hidden;
}
.custom-autocomplete-search.hide-select-arrow-on-hover:hover 
.ant-select-arrow {
  visibility: hidden;
}

/*  Workaround for select all checkbox alignment */
.ant-table-selection {
  margin-left: 10px !important;
  margin-top: 30px !important;
}

/* Grey out the selected label to make it appear editable */
.h-search-select:focus-within .ant-select-selector .ant-select-selection-item {
  color: #D7D9DD;
}

.custom-pagination {
  display: flex;
}

@media (max-width: 925px) and (min-width: 670px) {
  .custom-pagination {
    height: 110px;
  }
}

.custom-pagination li:first-child {
  order: 99;
}

.custom-pagination li {
  order: 0;
}

.bulk-selection-switch {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-right: 10px;
}

.bulk-switch-button {
  background-color: #EEEEEE;
  border: 1px solid #E7E7E7;
  display: block !important;
  align-items: center !important;
  padding: 11px 0;
}
.bulk-switch-button:hover {
  background-color: #EEEEEE !important;
}
.bulk-selection-enabled  {
  background-color: #003087 !important;
}
.bulk-selection-enabled:hover  {
  background-color: #003087 !important;
}
.bulk-selection-disabled .ant-switch-handle::before {
  background-color: #003087;
}
.select-count {
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
  background-color: #EBF3FF;
  color: #003087;
  border-radius: 8px;
  padding: 5px;
  padding-right: 8px;
}

.clr-btn {
  border: none;
  color: #003087;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.clear-selection-btn {
  color: #003087;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;

}

.clr-filter-btn {
  /* float: right; */
  margin-right: 25px;
  background-color: #ffffff;
  cursor: pointer;
}

.clr-filter-container {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 20px;
}

.table-row-checkbox-hide {
  visibility: hidden !important;
}

.table-row-checkbox-visible {
  visibility: visible !important;
}

.proctor-status {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.fixed-header-table thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.no-overflow-y {
  overflow-y: hidden;
}