label {
  color: black;
}
/*header*/
hr {
  margin: 0px;
  border-color: rgb(69, 89, 124);
}
.ant-layout-sub-header-background {
  background: #fff;
}
header {
  box-shadow: 0px 3px 12px 0px rgba(0 0 0/0.1);
  position: relative;
  z-index: 10;
}

.ant-layout-header {
  background: #fff;
  line-height: 48px;
  height: 48px;
  padding: 0px;
  /* box-shadow: 0px 3px 12px rgb(0 0 0/ 0.1); */
  font-family: Arial, Helvetica, sans-serif;
}
a {
  color: black;
}
.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: rgb(0, 48, 135);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-color: rgb(0, 48, 135);
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(98, 181, 229);
}
.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background: rgb(0, 18, 51);
}
.ant-menu-vertical .ant-menu-submenu-title {
  padding-left: 24px;
  display: flex;
  align-items: center;
}
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 58px;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  padding: 26px 24px;
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0px;
}
/* sidebar */
.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.version-container {
  color: rgb(102, 113, 133);
  padding: 20px 24px;
  text-align: left;
}
/*content*/
.ant-layout-content {
  background-color: #ffffff;
  height: calc(100vh - 72px);
  overflow-y: auto;
}
/*button*/
.page-header .ant-btn-primary {
  background: rgb(0, 48, 135);
  height: 48px;
  width: 76px;
  border: none;
  font-size: 16px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: white;
  background: #003ba7;
}
.ant-divider {
  border-top: 1px solid rgba(198, 198, 198);
}
.ant-divider-horizontal {
  margin-top: 0px;
}
.page-header .ant-btn-secondary {
  border: 1px solid black;
  color: black;
  height: 48px;
  width: 76px;
  font-size: 16px;
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  border: 1px solid black;
  background-color: rgb(232, 232, 250);
}

/* switch*/
.switch-status {
  margin-right: 18px;
  margin-left: auto !important;
}
.switch,
.switch-disabled {
  margin-right: 10px;
}
.ant-switch:focus {
  box-shadow: none;
} /* forms */
.ant-form-vertical .ant-form-item-label {
  padding: 0px !important;
}
.ant-form-large .ant-form-item-label > label {
  height: 0px;
}
.input:focus + .ant-form-large .ant-form-item-label > label {
  color: black;
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td {
  padding: 14px 0 13px 20px;
}
.ant-table.ant-table-middle .ant-table-thead > tr > th {
  padding: 12px 20px;
}
.table-cell-container {
  display: flex;
}
.table-cell-icon {
  display: flex;
  padding-right: 6px;
}
.main-container {
  font-weight: 500;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 6px;
  overflow-y: auto;
}
.eCloud-content-table {
  font-weight: 500;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 650px) {
  .ant-page-header-content {
    overflow-y: auto;
    height: 72vh;
  }
}
@media screen and (min-width: 821px) {
  .ant-page-header-content {
    overflow-y: auto;
    height: 81vh;
  }
}
.ant-table-pagination.ant-pagination {
  padding-bottom: 10px;
}

/* page header */
.page-header .ant-input-group-wrapper {
  width: max-content;
}
.page-header
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-input {
  font-size: 16px;
  align-self: center;
  color: black;
  border-radius: 4px;
}
.page-header
  .ant-input-search
  .ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 48px;
}

.ant-page-header-heading {
  padding-left: 24px;
  padding-right: 24px;
}
.select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #c6c6c6;
  height: 48px;
}

.search-bar .ant-btn {
  border-left: none;
  box-shadow: none;
  cursor: initial;
}
.search-bar .ant-btn:hover,
.ant-btn:focus {
  border-color: #d9d9d9;
}

.ant-page-header {
  height: 100vh;
  padding-left: 0px;
  padding-right: 0px;
}
.page-header
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 4px 4px 0;
  height: 48px;
}
.page-header .ant-page-header-heading-title {
  font-size: 24px;
}
/* page header menu responsive mobile*/
@media screen and (max-width: 370px) {
  .page-header .ant-btn-primary {
    height: 42px;
  }
  .page-header
    .ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    height: 42px;
  }
  .page-header
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    height: 42px;
  }
  .select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
  }
  .page-header .ant-page-header-heading-title {
    font-size: 18px;
  }
  .page-header.responsive .ant-page-header-heading {
    margin-top: -18px;
  }
  .select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    text-overflow: ellipsis;
    width: 110px;
  }
}

.page-header .ant-input-group-wrapper {
  vertical-align: middle;
}
.page-header .ant-select {
  vertical-align: middle;
}

.status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}
.status-text {
  padding-left: 8px;
}

.ant-select-dropdown {
  /* width: 160px !important; */
  border-radius: 4px;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 5px 5px 12px rgb(0 0 0/0.1);
}
.ant-select-item-option-content {
  font-size: 16px;
  padding: 5px 0;
  color: black;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: white;
  font-weight: 400;
}
.select
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: rgb(199, 213, 239);
  box-shadow: none;
}
/*modal css*/
.ant-modal-header,
.ant-modal-footer {
  border: none;
  padding: 24px;
}
.ant-modal-title {
  font-size: 24px;
  font-weight: bold;
}
.ant-modal-body {
  padding: 0px 24px;
  font-size: 16px;
}
.ant-modal-footer .ant-btn {
  color: black;
  border-radius: 100px;
  border: 1px solid black;
  height: 48px;
}
.ant-modal-footer .ant-btn:hover,
.ant-modal-footer .ant-btn:focus {
  border: 1px solid black;
  background-color: rgb(232, 232, 250);
  height: 48px;
  border-radius: 100px;
}

.ant-modal-footer .ant-btn-primary {
  background: rgb(0, 48, 135);
  border-radius: 100px;
  border: none;
  color: white;
  margin-left: 16px;
}
.ant-modal-footer .ant-btn-primary:hover,
.ant-modal-footer .ant-btn-primary:focus {
  color: white;
  border: none;
  background: #003ba7;
  border-radius: 100px;
  margin-left: 16px;
}

.page-header .ant-picker {
  border: 1px solid rgb(198, 198, 198);
  border-radius: 4px;
}

.page-header.responsive .ant-page-header-heading-extra {
  display: contents;
}
.page-header.responsive .ant-page-header-heading-left {
  margin: 15px 0px;
}
.page-header.responsive .ant-input-group-wrapper {
  width: 52%;
  margin-left: 0px;
}
.page-header.responsive .ant-page-header-heading {
  align-items: center;
  margin-top: -10px;
}
.page-header.footer {
  position: fixed;
  bottom: 0;
  text-align: end;
  background: #f0f3fc;
  width: 100%;
  padding: 18px 35px;
}

/* dropdown form for client proctors*/
.client-form-search
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
  border-color: rgb(0, 48, 135);
}

.client-form-search
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.client-form-search
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border: 1px solid rgb(198, 198, 198);
  border-radius: 4px;
  color: black;
}

.ant-picker-large .ant-picker-input > input {
  color: black;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgb(247, 248, 252);
}

.ant-input[disabled] {
  color: black;
  background-color: #f5f5f5;
}
.ant-input[disabled]:hover {
  color: black;
  background-color: #f5f5f5;
  border: 1px solid rgb(198, 198, 198);
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.switch,
.switch-disabled {
  background-color: #e3e3ea;
  padding-bottom: 22px;
}
.switch {
  background-color: #fff !important;
  border: 1.2px solid #003087;
  padding-bottom: 22px;
}
/* .ant-switch-handle::before{
  background-color: rgb(98, 181, 229);
} */
/* .switch:hover, .switch-disabled:hover {
  background: inherit;
  color: inherit;
} */
.switch .ant-switch-handle::before {
  background-color: #62b5e5;
}
.switch-disabled .ant-switch-handle::before {
  background-color: #fff;
}

/*responsive design*/
.ant-list-lg .ant-list-item {
  border-bottom: none;
  padding-left: 24px;
  padding-right: 24px;
}

.ant-list-vertical .ant-list-item-meta,
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 2px;
}
.list .ant-typography {
  display: flex;
}
.ant-layout-sider-zero-width-trigger {
  height: 60px;
  width: 65px;
  line-height: 60px;
  background-color: #001233;
  top: 0;
  border: none;
  right: -65px;
}

.list .ant-divider-horizontal {
  margin: 0px 0 0 -25px;
  width: 117%;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-top: 4px;
  color: white;
}
.drawer-logo {
  display: flex;
  justify-content: center;
  padding: 5px 0px 8px 0px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #001233;
  color: white;
  width: 100%;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
}

.drawer-version {
  color: rgb(102, 113, 133);
  padding: 20px 24px 24px 24px;
  text-align: left;
}
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: rgb(198, 198, 198) !important;
}

.user-type-dropdown-source {
  width: 130px;
  height: 48px;
}

/* Navigation Layout */
.mobile-header {
  background-color: #001233;
  height: 60px;
  display: flex;
  align-items: center;
}
.mobile-header .ant-btn-icon-only {
  height: 64px;
  width: 64px;
  background-color: #001233;
}
.mobile-header .ant-btn-primary {
  border: none;
  background-color: #001233;
}
.mobile-header .title {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: -50px;
}
.mobile-sidebar .ant-drawer-body {
  background: #001233;
  padding: 0px;
  overflow-x: hidden;
}
.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}
.top-nav > div:first-child {
  display: flex;
  gap: 20px;
  margin-left: 15px;
  height: 100%;
}
.logo-main {
  display: block; /* Ensures no extra spacing */
  max-width: 120px;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.menu-cls {
  display: flex;
}
.menu-cls-item {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 40px !important;
  font-size: 18px !important;
}
.top-nav-items-right {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 20px;
  height: 100%;
}
.app-version {
  color: #8c8c8c;
}
.header-user-dropdown {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  color: #003087;
  border-radius: 50px;
  height: 40px;
  padding: 0 5px;
}
.nav-profile-icon {
  margin-right: -5px;
}
.logout-text {
  font-size: 16px !important;
}

.ant-dropdown-menu {
  border: 1px solid #DBDBDB;
  box-shadow: 0px 4px 12px 0px #00000033;
  border-radius: 12px !important;
  color: #fff;
  padding: 12px 16px !important;
}
.ant-dropdown-menu:hover li {
  background-color: #FFF !important;
}
.logout-item-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  margin-right: 15px !important;
}
.logout-item-row :hover {
  background-color: #FFF !important;
}
.nav-logout-item :hover {
  background-color: #FFF !important;
}
.nav-logout-item {
  width: 100% !important;
  height: 100% !important;
}
/* Top navigation and logo styles */
.top-nav-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extra-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infinite-scroll-container {
  height: calc(100vh - 250px);
  overflow-y: auto;
}
.infinite-scroll-container .infinite-scroll {
  overflow: hidden;
}

.login-validation-container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}
.login-validation-container div {
  display: flex;
  align-items: center;
}
.login-validation-text {
  font-size: 14px !important;
  /* white-space: nowrap; */
}

.delete-btn-none {
  border: 2px solid #003087 !important;
  color: #003087 !important;
}

/* .ant-table-thead th {
  padding-left: 12px !important;
} */

.ant-table-thead th:first-child {
  /* padding-left: 12px !important; */
}
.custom-row {
  height: 80px !important;
}

.custom-row .ant-table-cell {
  padding: 12px 16px;
}
/* MIGHT NEED TO REVERT LATER */
.table-container {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.custom-header-top {
  margin: 20px 0;
}
.page-header-listing {
  margin: 0 9px;
}
.customheader-left-section {
  display: flex;
  align-items: center;
}
.customheader-left-section h4 {
  margin: 0 15px;
}

.switch-button-form-disabled {
  background-color: #e8e8e8 !important;
  border: none !important;
  margin-right: 10px;
}
.switch-button-form-disabled .ant-switch-handle::before {
  background-color: #9a9a9a !important;
}

.required-asterisk {
  margin-left: 1px;
  color: #ff4d4f;
  font-weight: 200;
}

.eCloud-table {
  /* width: 100%;
      padding: 0px 24px; */
}
.eCloud-table .ant-table-thead > tr > th {
  color: rgba(154 154 154);
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  font-family: Arial, Helvetica, sans-serif;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(198, 198, 198);
  border-top: 1px solid rgb(198, 198, 198);
  /* transition: background 0.3s ease; */
}
.eCloud-table .ant-table-container table > thead > tr:first-child th:last-child,
.ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0px;
}
.eCloud-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background: none;
}
.eCloud-table .ant-table-tbody > tr > td {
  border-bottom: none;
}
.eCloud-table .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid rgb(198, 198, 198);
}
.eCloud-table > tr {
  border: 1px solid rgb(198, 198, 198);
}
.eCloud-table-row-light {
  background-color: #ffffff;
}

.eCloud-table-row-dark {
  background-color: rgb(247, 248, 252);
}
.eCloud-table .ant-table .ant-table-tbody > tr:hover > td {
  background: inherit;
  border-top: 1px solid rgb(235, 235, 235);
  border-bottom: 1px solid rgb(235, 235, 235);
  /* -moz-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08); */
}
.eCloud-table .ant-table .ant-table-tbody > tr:hover {
  cursor: pointer;
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
}

.eCloud-table .ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next,
.ant-pagination-item,
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 30px;
  color: black;
  margin-right: 12px;
}
.eCloud-table .ant-pagination-item-active a,
.ant-pagination-item:hover a {
  color: black;
}
.ant-pagination.mini
  .ant-pagination-item:hover:not(.ant-pagination-item-active) {
  color: rgb(0, 48, 135);
  border-radius: 30px;
  border: 1px solid rgb(0, 48, 135);
}
.eCloud-table .ant-pagination-item-active {
  /* border: 1px solid rgb(221, 221, 221); */
  /* line-height: 24px; */
  border-radius: 30px;
  border: 1px solid rgb(0, 48, 135);
  background-color: rgb(247, 248, 252);
}
.eCloud-table .ant-pagination.mini .ant-pagination-item {
  margin-right: 12px;
}

.resend-activation-link {
  color: rgb(232, 119, 34);
  text-decoration: none;
  padding-left: 12px;
  width: 100%;
  cursor: pointer;
  /* white-space: nowrap; */
}
.resend-activation-link-text {
  color: rgb(232, 119, 34);
  font-size: 16px;
  float: right;
}
.resend-activation-link-text:hover {
  color: #ff9c52;
  font-size: 16px;
  float: right;
}
.resend-activation-link-text-client {
  color: rgb(232, 119, 34);
  font-size: 14px !important;
}
.resend-activation-link-text-client:hover {
  color: #ff9c52;
}

/* Adjust the checkbox size */
.ant-table .ant-checkbox-wrapper,
.ant-table .ant-checkbox {
  transform: scale(1.2); /* Increase checkbox size */
  margin-left: 5px; /* Adjust spacing */
}

/* MISC STYLES */
.custom-anticon-loader {
  font-size: 36 !important;
  color: rgb(0, 48, 135);
  font-weight: bold;
}

/* Moved inline styles */
.table-container-header {
  padding-bottom: 15px !important;
  padding-top: 5px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top-width: 0 !important;
  transition: all 0.4s ease !important;
}
.table-container-data {
  padding-left: 10px !important;
  padding-right: 10px !important;
  transition: all 0.4s ease !important;
}
.table-container-data:not(:first-child) {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.table-header-with-children {
  width: 100% !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-right: 1px solid #D7D9DD !important;
  gap: 5px !important;
}
.table-header-children {
  display: block;
  text-wrap: nowrap;
  padding-top: 10px;
  font-weight: 400;
  line-height: 1.172em;
}
.clear-icon {
  font-size: 0.875rem;
  color: #4C4C4C;
  display: inline-block;
}
.search-icon {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.88);
}
.search-select-input-filter {
  width: 100%;
  overflow-x: hidden;
}
.search-select-input-filter.hide-arrow-on-hover:hover .ant-select-arrow {
  visibility: hidden;
}
